<template>
  <div class="challenge" v-if="challenge">
    <img class="logo" v-bind:src="'/server/logos/'+challenge.away.logo" height="100" />
    <span class="at">@</span>
    <img class="logo" v-bind:src="'/server/logos/'+challenge.home.logo" height="100" />
    <hr style="margin-bottom: 8px">
    <div style="text-align:center;">
      Opening Vegas Odds:
    </div>
    <div class="center-text" v-if="challenge.results">
      <b-badge class="vegas" variant="success" v-if="challenge.results.winner.name === challenge.line.name">Line: {{challenge.line.name}}</b-badge>
      <b-badge class="vegas" variant="danger" v-else>Line: {{challenge.line.name}}</b-badge>
      <b-badge class="vegas" variant="success" v-if="challenge.results.spread.name === challenge.line.name">Spread: {{challenge.spread}}</b-badge>
      <b-badge class="vegas" variant="danger" v-else>Spread: {{challenge.spread}}</b-badge>
      <b-badge class="vegas" variant="success" v-if="challenge.results.totalpoints === 'OV'">Total: {{challenge.totalpoints}}</b-badge>
      <b-badge class="vegas" variant="danger" v-else>Total: {{challenge.totalpoints}}</b-badge>
    </div>
    <div class="center-text" v-else>
      <b-badge class="vegas" variant="secondary">Line: {{challenge.line.name}}</b-badge>
      <b-badge class="vegas" variant="secondary">Spread: {{challenge.spread}}</b-badge>
      <b-badge class="vegas" variant="secondary">Total: {{challenge.totalpoints}}</b-badge>
    </div>
    <div style="text-align:center; color: white; padding-top: 8px;">
      <em style="font-size: 12px;"> Kickoff at {{ challenge.eventDate | moment("ddd, MM/D") }} {{ challenge.eventTime | moment("h:mmA") }} // {{challenge.bets.length}} bets placed.</em>
    </div>

  </div>
</template>

<script>
export default {
  name: "Faceoff",
  props: {
    challenge: null,
  },
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.vegas {
  margin: 5px;
}
.challenge {
  text-align: center;
}
.at {
  padding: 15px;
  font-size: large;
  font-weight: 700;
  font-size: x-large;
}

</style>