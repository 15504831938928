<template>
  <div>
    <div v-for="challenge in challenges" :key="challenge.id">
      <div class="challenge" @click="viewChallenge(challenge.id)">
        <b-card no-body>
          <b-card-header>{{challenge.away.name}} at {{challenge.home.name}}</b-card-header>
          <b-card-body>
            <Faceoff :challenge="challenge" />
          </b-card-body>
        </b-card>
        <hr />
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import Faceoff from "@/components/Faceoff";

export default {
  name: "SubmitResults",
  data() {
    return {
      challenges: null,
    };
  },
  components: {
    Faceoff,
  },
  created() {
    let self = this;
    api(
      `query{ needresults{ id spread totalpoints line { name } home { id name logo mascot } away { id name logo mascot } eventDate eventTime bets { id } } }`
    ).then((data) => {
      self.challenges = data.needresults;
    });
  },
  methods: {
    viewChallenge(cid) {
      this.$router.push({
        name: "SubmitResult",
        params: { id: cid },
      });
    },
  },
};
</script>

<style scoped>
.logo {
  margin: 20px;
}
.challenge {
  cursor: pointer;
  text-align: center;
}
</style>
